.row {
  display: flex;
  justify-content: space-between;
  padding: var(--u2) 0;
  font-size: var(--font-16);

  .text {
    margin: 0;
    color: var(--text-color);
    font-weight: 500;
  }

  .values {
    margin: 0;
    display: flex;
    gap: var(--u);
    position: relative;
    font-weight: 600;
    min-width: fit-content;
    align-items: center;

    .value {
      min-width: var(--u6);
      text-align: right;
    }
  }
}

.line {
  border-bottom: var(--dashed-line);
}
