.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--u) 0;
  font-size: var(--font-16);
  color: var(--text-color);
  text-align: left;
  gap: var(--u2);

  .text {
    margin: 0;
    color: var(--text-color);
    font-size: var(--font-16);
    font-weight: 500;
  }

  .valueContainer {
    display: flex;
    gap: var(--u);
    align-items: center;

    .value {
      width: auto;
      text-align: right;
      margin: 0;
      margin-left: var(--smallUnit);
      font-weight: 600;
    }
  }
}

.line {
  border-bottom: var(--dashed-line);
}
