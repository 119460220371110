.tooltipContainer {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  .tooltipUp {
    display: block;
    position: absolute;
    background: var(--tooltip-background);
    padding: var(--u) var(--u2);
    z-index: var(--layer-4);
    bottom: 125%;
    border-radius: var(--smallUnit);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
    height: fit-content;

    .arrow {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--tooltip-background);
      position: absolute;
      bottom: -5px;
      right: calc(50% - 5px);
    }

    .text {
      width: max-content;
      max-width: 300px;
      margin: 0;
      font-size: var(--font-14);
      color: var(--white);
      font-weight: normal;
    }
  }

  .tooltipDown {
    @extend .tooltipUp;
    top: 120%;
    bottom: 0;

    .arrow {
      @extend .arrow;
      border-top: none;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--tooltip-background);
      bottom: 0;
      top: -5px;
    }

    .text {
      @extend .text;
    }
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }
}
