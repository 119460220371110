.row {
  display: flex;
  flex-direction: column;
  padding: var(--u2) 0;
  font-size: var(--font-16);
  color: var(--text-color);
  text-align: left;
  gap: var(--u2);

  .text {
    margin: 0;
    color: var(--text-color);
    font-family: var(--secondary-font);
    font-size: var(--font-10);
  }

  .value {
    width: auto;
    text-align: right;
    margin: 0;
    margin-left: var(--smallUnit);
    font-size: 24px;
  }
}

.line {
  border-bottom: var(--dashed-line);
}
