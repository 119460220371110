.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-10);
  font-weight: 500;
  font-family: var(--secondary-font);
  border-bottom: var(--dashed-line);
  padding-bottom: var(--u2);

  p {
    margin: var(--u) 0;
  }

  .gasCost {
    width: 65%;
    text-align: right;
  }
}
