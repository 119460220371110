.button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: var(--primary);
  color: var(--white);
  border: 0;
  transition: 0.25s all;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--primary-hover);
    transition: 0.25s all;
  }
}

.secondary {
  background: var(--secondary);

  &:hover {
    background: var(--secondary-hover);
  }
}

.tertiary {
  background: var(--background);
  color: var(--primary);

  &:hover {
    /*couldn't find another way to make this work without declaring a new variable that contemplates the alpha directly 
      rgba(var(--primary), 0.08) doesn't work. This does.
    */
    background-color: rgba(#008cf4, 0.08);
  }
}

.quaternary {
  background: var(--background);
  color: var(--secondary);

  &:hover {
    background-color: rgba(#53d3be, 0.08);
  }
}

.disabled {
  background: var(--gray-200);
  color: var(--white);

  &:hover {
    background: var(--gray-200);
    color: var(--white);
  }
}

.secondaryDisabled {
  color: var(--gray-200);
  background: var(--background);
  border: 1px solid var(--gray-200);

  &:hover {
    color: var(--gray-200);
    background: var(--background);
    border: 1px solid var(--gray-200);
  }
}
