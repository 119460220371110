.sectionContainerEditable {
  max-width: var(--maxWidth);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: var(--u5);
  text-align: center;
  align-items: center;

  .titleContainer {
    display: flex;
    gap: var(--u);
    text-align: center;
    align-items: center;

    .title {
      font-size: var(--font-20);
      font-weight: bold;
    }
  }

  .maturityContainer {
    display: flex;
    gap: var(--u);
    align-items: center;

    .title {
      font-size: var(--font-14);
      margin: 0;
      font-weight: 500;
    }
  }
}

@media (max-width: 769px) {
  .sectionContainerEditable {
    max-width: 100%;
    padding: 20px;
  }
}
