.container {
  width: 100%;
  min-width: 120px;
  height: var(--u6);

  .placeholder {
    width: 100%;
    text-align: left;
  }

  .select {
    border: none;
    color: var(--text-color);
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: var(--u6);
    background: var(--background);

    .arrow {
      border-color: var(--secondary-text-color) transparent transparent;
      top: 22px;
      margin-right: var(--u);
    }
  }

  .menu {
    text-align: left;
    margin-top: -5px;
    border: none;
    box-shadow: var(--dropdown-shadow);
    background: var(--background);
    color: var(--text-color);

    div[aria-selected='true'] {
      background-color: rgba(0, 140, 244, 0.1);
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background-color: var(--background);
    }

    &::-webkit-scrollbar {
      width: var(--u);
      background-color: var(--background);
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background-color: var(--primary);
    }

    div {
      color: var(--text-color);
      text-align: left;
      padding: var(--smallUnit) 1px;
    }
  }
}

.containerEditable {
  @extend .container;
  min-width: 125px;

  .selectEditable {
    @extend .select;
    border: none;
    padding: 0;
    font-weight: 600;
    justify-content: center;

    .arrowEditable {
      @extend .arrow;
      margin: 0 -10px;
    }
  }
  .menuEditable {
    @extend .menu;
    position: absolute;
    right: var(--u);
  }
}
.selectEditable:hover {
  box-shadow: none !important;
}

.arrowClosed {
  border-style: solid;
  border-width: 5 px 5 px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10 px;
  top: 14 px;
  width: 0;
}
