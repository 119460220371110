.row {
  display: flex;
  flex-direction: column;
  padding: var(--u2) 0;
  text-align: left;

  .text {
    margin: 0;
    color: var(--text-color);
    font-family: var(--secondary-font);
    font-size: var(--font-10);
  }
}

.line {
  border-bottom: var(--dashed-line);
}
