.assetContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: var(--dashed-line);
  color: var(--text-color);

  .title {
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: var(--font-10);
  }

  .informationContainer {
    width: 50%;
    display: flex;
    gap: var(--u);
    flex-direction: column;
    gap: var(--smallestUnit);

    .assetName {
      margin: 0;
      font-weight: 600;
      font-size: var(--font-20);
    }
  }

  .assetPriceContainer {
    display: flex;
    gap: var(--u);
    flex-direction: column;
    gap: var(--smallestUnit);
    text-align: right;

    .price {
      margin: 0;
      font-weight: 700;
      font-size: var(--font-16);
    }

    .secondaryPrice {
      margin: 0;
      font-size: var(--font-12);
      color: var(--secondary-text-color);
      font-family: var(--secondary-font);
    }
  }
}

.skeleton {
  align-self: center;
}
