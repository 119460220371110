.faucetContainer {
  display: flex;
  flex-direction: column;
  gap: var(--u);

  .titlesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin: var(--u) 0;
    }

    .addTokens {
      margin: 0;
      font-size: var(--font-14);
      color: var(--primary);
      cursor: pointer;

      &:hover {
        color: var(--primary-hover);
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-100);
    gap: var(--u);

    p {
      width: 30%;
      font-size: var(--font-14);
      font-weight: 600;
      color: var(--gray-500);
      margin: var(--u) 0;
    }
  }

  .assetContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--u);
    padding: var(--u2) 0;

    &:last-child {
      border-bottom: none;
    }

    .asset {
      display: flex;
      align-items: center;
      gap: var(--u);
      font-size: var(--font-18);
      font-weight: 600;
      margin: 0;
    }

    .buttonContainer {
      width: 30%;
      height: 30px;
    }
  }
}
