.operationContainer {
  display: flex;
  flex-direction: column;
  width: 75%;
  gap: var(--u);
  padding-left: var(--u3);
  border-left: var(--solid-line);
}

@media (max-width: 769px) {
  .operationContainer {
    padding-left: 0;
    border-left: 0;
    width: 100%;
  }
}
