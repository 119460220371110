.container {
  max-width: var(--maxWidth);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--u3);

  .titleContainer {
    display: flex;
    gap: var(--u);
    text-align: center;
    align-items: center;

    .title {
      font-size: var(--font-20);
      font-weight: bold;
      margin: 0;
    }
  }

  .selectContainer {
    max-width: 100px;
    .labelContainer {
      display: flex;
      align-items: center;
      gap: var(--u);

      .marketImage {
        height: var(--u3);
        width: var(--u3);
        object-fit: cover;
      }
      .marketName {
        font-size: var(--font-14);
      }
    }
  }
}
