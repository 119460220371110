.operationsSelector {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: var(--u2);
  padding-right: var(--u2);

  .section {
    display: flex;
    flex-direction: column;
    gap: var(--u);
    .title {
      margin: 0;
      padding: 0;
      font-size: var(--font-18);
      text-align: center;
      color: var(--gray-900);
      font-weight: 600;
      font-family: articulat-cf;
    }

    .list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: var(--u);
      text-align: center;
      color: var(--primary);

      .action {
        cursor: pointer;
        padding: var(--u) var(--u2);
      }

      .activeAction {
        @extend .action;
        background: var(--primary-50);
      }
    }
  }
}

@media (max-width: 769px) {
  .operationsSelector {
    display: none;
  }
}
