.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: var(--u) 0;
  flex-wrap: wrap;

  .step {
    width: 11px;
    height: 11px;
    background: var(--secondary-text-color);
    position: relative;

    &:not(:first-child):after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 1700%;
      border-bottom: var(--solid-line);
      border-width: 2px;
      z-index: var(--layer-0);
    }
  }

  .selectedStep {
    @extend .step;
    background: var(--primary);

    &:not(:first-child):after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      border-bottom: var(--solid-line);
      border-color: var(--primary);
      border-width: 2px;
      z-index: var(--layer-0);
    }
  }
}

.text {
  font-size: var(--font-12);
  color: var(--text-color);
  width: 100%;
  margin: 0;
  margin-top: var(--u2);
  font-family: var(--secondary-font);
}

.skeleton {
  @extend .steps;
  margin: var(--u) 0;
}
