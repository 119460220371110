.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--u) 0;

  .text {
    width: 100%;
    margin: 0;
    color: var(--text-color);
    font-size: var(--font-10);
    font-family: var(--secondary-font);
    font-weight: 500;
  }

  .editable {
    gap: var(--smallUnit);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    max-height: var(--u2);

    .value {
      margin: 0;
      color: var(--text-color);
      font-size: var(--font-10);
      font-family: var(--secondary-font);
      font-weight: 500;
      overflow-x: hidden;
    }

    .inputContainer {
      position: relative;
      border: none;
      padding: 0;
      height: var(--u4);
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: var(--solid-line);

      .input {
        width: 100%;
        border: none;
        outline: none;
        text-align: center;
        padding: 0;
        color: var(--text-color);
        font-weight: 600;
        text-align: right;
        font-family: inherit;
        background: none;
        font-size: var(--font-16);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .symbol {
        margin: 0;
        color: var(--text-color);
        font-weight: 600;
      }
    }
    .arrow {
      cursor: pointer;
    }
  }
}

.line {
  border-bottom: var(--dashed-line);
}
