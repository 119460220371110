.inputSection {
  position: relative;
  border-bottom: var(--solid-line);
  padding: var(--u2) 0 var(--u) 0;

  .input {
    font-family: inherit;
    width: 95%;
    border: none;
    outline: none;
    font-size: var(--font-64);
    color: var(--text-color);
    background: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .translatedValue {
    margin: 0;
    font-size: var(--font-14);
    color: var(--secondary-text-color);
    max-width: 100%;
    font-family: var(--secondary-font);
  }

  .max {
    position: absolute;
    margin: 0;
    top: 35%;
    right: 0;
    cursor: pointer;
    color: var(--primary);
    font-size: var(--font-12);
    font-weight: 700;
  }
}

.error {
  @extend .inputSection;
  border-bottom: 1px solid var(--error);
}
